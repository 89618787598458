<template>
    <form-hero-none v-if="type === 'NONE'">
        <slot />
    </form-hero-none>
    <form-hero-split :hero="hero" :content="content" v-else-if="type === 'SPLIT_LEFT'">
        <slot />
    </form-hero-split>
    <div v-else class="flex h-screen" :class="{ 'overflow-y-auto items-start': type === 'NONE', 'overflow-hidden': type !== 'NONE' }">
        <fun-bg v-if="type === 'NONE'" />

        <div :class="fullHeroClasses" :style="`${type === 'FULL' ? hero : ''}`">
            <div v-if="type !== 'NONE'" class="absolute top-0 h-full" :class="contentOverlayClasses" :style="bgOverlay">
                <form-hero-content :content="content" />
            </div>

            <div v-if="type !== 'FULL' && type !== 'NONE'" :style="hero"></div>
            <div
                :class="{
                    relative: true,
                    'flex-1 justify-center overflow-y-auto bg-white': ['SPLIT_LEFT', 'SPLIT_RIGHT'].includes(type),
                    'flex flex-1 items-center': shouldCenter,
                    'flex-1 top-[60px] sm:top-[150px]': !shouldCenter && (type === 'NONE' || type === 'FULL'),
                }"
            >
                <div ref="wrapper" class="form-container">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import FunBg from '@/Components/FunBg.vue'
import FormHeroContent from '@/Pixelform/Client/FormHero/Content.vue'
import FormHeroNone from './FormHeroNone.vue'
import FormHeroSplit from './FormHeroSplit.vue'
import { computed, ref } from 'vue'

const props = defineProps({
    type: {
        type: String,
        default: 'NONE',
        required: true,
    },
    hero: {
        type: Object,
        required: true,
    },
    content: {
        type: [Array, Object],
    },
})

const fullHeroClasses = computed(() => {
    return {
        'flex flex-col sm:flex-row': true,
        'flex-row-reverse': props.type === 'SPLIT_RIGHT',
        'flex-1 h-full overflow-hidden': !['SPLIT_LEFT', 'SPLIT_RIGHT'].includes(props.type),
        'flex-1': ['SPLIT_LEFT', 'SPLIT_RIGHT'].includes(props.type),
        'overflow-y-auto': !shouldCenter,
    }
})
const contentOverlayClasses = computed(() => {
    return {
        'left-0': props.type === 'SPLIT_LEFT',
        'right-0': props.type === 'SPLIT_RIGHT',
        'left-0 !w-full': props.type === 'FULL',
    }
})

const bgOverlay = computed(() => {
    return {
        backgroundColor: props?.backgroundSolid ?? props.overlay,
        width: props.width,
    }
})
// const hero = computed(() => {
//     let width = props.width

//     if (props.backgroundSolid) {
//         return `width: ${width}; background-color: ${props.backgroundSolid};`
//     }

//     return `width: ${width}; background-size: ${props.size}; background-position: ${props.position}; background-repeat: ${props.repeat}; background-image:url(${props.backgroundUrl});`
// })

const wrapper = ref(null)
const shouldCenter = computed(() => {
    if (wrapper.value) {
        return wrapper.value.clientHeight < window.innerHeight * 0.7
    }

    return false
})
</script>
