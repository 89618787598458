<template>
    <Smooth>
        <div>
            <form-logo
                :form-name="form.name"
                :logo="form.logo"
                :hero-type="form.hero_type"
            />

            <h3 class="mb-4 leading-relaxed" v-text="props.form.name" />

            <div v-if="error" class="error" v-text="error" />
            <form
                class="space-y-4"
                method="post"
                @submit.prevent="handleSubmit"
            >
                <pf-field-renderer
                    ref="fieldRenderer"
                    v-for="item in currentStepLayout"
                    :field="item"
                    :form="userForm"
                    :key="item.name"
                />
                <form-buttons
                    :step="step"
                    :form-submit-disabled="formSubmitDisabled"
                    @onNextPress="confirmStepIsValid(step)"
                    @onPrevPress="step -= 1"
                    :is-last-step="isLastStep"
                />
            </form>
            <div
                v-if="form.logo && form.hero_type === 'NONE'"
                class="branding-footer -mb-[25px] md:-mx-[40px] md:-mb-[45px] rounded-b-lg border-t -mx-[25px] sm:-mx-[25px] h-5 bg-gradient-to-b from-slate-100 mt-5 sm:mt-10"
            ></div>
        </div>
    </Smooth>
</template>
<script setup>
import axios from 'axios'
import FormButtons from './FormButtons.vue'
import PfFieldRenderer from '@/Pixelform/FieldRenderer.vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { ref, computed, onMounted } from 'vue'
import Smooth from '@/Components/Smooth.vue'
import FormLogo from '@/Pixelform/Client/FormLogo.vue'
import { jumpToFirstErroringFormStep, getFieldNameMap } from '@/utils'

const emit = defineEmits(['done'])

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
})

const formId = props.form._id
const step = ref(0)
const fieldRenderer = ref(null)
const error = ref(null)
const formSubmitDisabled = ref(false)
const currentStepLayout = computed(
    () => layoutChildren.value[step.value].children
)
const isPaymentForm = props.form.fields.some((f) => f.type === 'payment')
const fields = getFieldNameMap(props.form.fields) // { first_name: null, ... }

const isMultiStepForm = computed(() => props.form.layout.type === 'MULTI_STEP')
const layoutChildren = computed(() => props.form.layout.children)
const isLastStep = computed(
    () => step.value === layoutChildren.value.length - 1
)
const hasPaymentField = computed(() =>
    props.form.fields.some((f) => f.type === 'payment')
)

const userForm = useForm({
    ...fields,
    _token: props.form.token,
    _honeypot: '',
})

const confirmStepIsValid = async (idx) => {
    try {
        await axios.post(`/f/${formId}/step-validation/${idx}`, userForm.data())
        if (isMultiStepForm.value && !isLastStep.value) {
            step.value += 1
        }

        return true
    } catch (err) {
        if (err.response.status === 422) {
            const errors = err.response.data?.errors
            if (!errors) {
                setFormErrors(err.response.data)
            } else {
                setFormErrors(errors)
            }
        }

        return false
    }
}

const handleSubmit = async () => {
    if (formSubmitDisabled.value) return false
    formSubmitDisabled.value = true

    const stepValue = isMultiStepForm.value ? step.value : 0
    const valid = await confirmStepIsValid(stepValue)

    if (!valid) {
        formSubmitDisabled.value = false
        return false
    }

    let paymentIntentId = null

    if (hasPaymentField.value) {
        formSubmitDisabled.value = true
        try {
            const pi = await createPaymentIntent()
            await confirmPayment(pi?.client_secret)
            paymentIntentId = pi?.id
        } catch (error) {
            // error.value = error.message
            formSubmitDisabled.value = false
            paymentIntentId = null
            return false
        }
    }

    try {
        const payload = {
            ...userForm.data(),
            payment: hasPaymentField.value
                ? {
                      billing_details: userForm.data().payment.billing_details,
                      payment_intent: paymentIntentId,
                  }
                : undefined,
        }

        await axios.post(`/f/${formId}`, payload)
        onFormSuccess()
    } catch (err) {
        setFormErrors(err.response.data.errors)
    } finally {
        formSubmitDisabled.value = false
    }
}

const onFormSuccess = () => {
    error.value = null
    if (props.form.return_url) {
        window.location.href = props.form.return_url
    } else {
        emit('done')
    }
}

const setFormErrors = (errors) => {
    const entries = Object.entries(errors)

    for (const entry of entries) {
        const [field, error] = entry

        // @ts-ignore
        userForm.setError(field, error)
    }

    if (isMultiStepForm.value) {
        step.value = jumpToFirstErroringFormStep(errors, layoutChildren.value)
    }

    if (errors?.code === 'plan_limit_reached') {
        error.value =
            "Form submission wasn't stored because plan limit has been reached."
    }
}

const createPaymentIntent = async () => {
    try {
        error.value = null
        const { data } = await axios.post(
            route('create-confirm-intent', { form: props.form._id }),
            {}
        )
        return data
    } catch (err) {
        const data = err.response.data
        if (data?.code === 'plan_limit_reached') {
            error.value =
                "Form submission wasn't stored. Plan limit has been reached."
        } else {
            error.value =
                'An error occurred while processing your request. Please try again after refreshing the page.'
        }
    }
}

const cardElement = ref(null)
const stripeAccount = usePage().props.form?.stripe_account_id
const stripe =
    isPaymentForm && stripeAccount
        ? Stripe(import.meta.env.VITE_STRIPE_KEY, {
              stripeAccount,
          })
        : null

const confirmPayment = async (paymentIntentSecret) => {
    const result = await stripe.confirmCardPayment(paymentIntentSecret, {
        payment_method: {
            card: cardElement.value,
        },
    })

    const errMsgDiv = document.querySelector('#stripe-error-message')

    if (result.error) {
        errMsgDiv.textContent = result.error.message
        return Promise.reject(result.error)
    } else {
        errMsgDiv.textContent = ''
    }

    return result
}

onMounted(async () => {
    if (!stripe) return

    const interval = setInterval(() => {
        if (document.querySelector('#card-element')) {
            clearInterval(interval)
            setupStripe()
        }
    }, 1000)
})

const setupStripe = async () => {
    const elements = stripe.elements()
    cardElement.value = elements.create('card')
    cardElement.value.mount('#card-element')
}
</script>
<style scoped>
.error {
    @apply my-4 text-sm text-red-700;
}
.title {
    @apply mb-4 leading-relaxed;
}
</style>
