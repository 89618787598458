<template>
    <pf-button
        v-if="showFinalSubmitButton"
        type="submit"
        class="justify-center block w-full"
        :class="{
            'opacity-50 cursor-not-allowed': formSubmitDisabled,
        }"
    >
        {{ formSubmitLabel }}
    </pf-button>

    <pf-button v-else-if="showNextButton" type="button" @click="$emit('onNextPress')" class="justify-center block w-full"> Next </pf-button>

    <pf-button v-if="showBackButton" :secondary="true" @click="$emit('onPrevPress')" type="button" class="justify-center block w-full py-4"> Back </pf-button>
</template>

<script setup>
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'
import pfButton from '@/Pixelform/Button.vue'

const emit = defineEmits(['onNextPress', 'onPrevPress'])

const props = defineProps({
    step: Number,
    isLastStep: Boolean,
    formSubmitDisabled: Boolean,
})

const form = usePage().props.form
const paymentField = computed(() => form?.fields?.find((f) => f.type === 'payment'))

const formSubmitLabel = computed(() => {
    if (!paymentField.value) {
        return form?.layout?.children[props.step]?.submit_button
    }

    const currencySymbol = () => {
        switch (paymentField.value.currency) {
            case 'USD':
                return '$'
            case 'GBP':
                return '£'
            case 'EUR':
                return '€'
            default:
                return paymentField.value.currency
        }
    }
    return `Pay ${currencySymbol()}${paymentField.value.price / 100}`
})
const isMultiStepForm = computed(() => form?.layout?.type === 'MULTI_STEP')
const showNextButton = computed(() => isMultiStepForm.value && !props.isLastStep)
const showBackButton = computed(() => isMultiStepForm.value && props.step > 0)
const showFinalSubmitButton = computed(() => !isMultiStepForm.value || (isMultiStepForm.value && props.isLastStep))
</script>
