<template>
    <div class="text-xs text-gray-400">{{ step + 1 }} of {{ stepsCount }} ― {{ title }}</div>
</template>
<script setup>
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

const props = defineProps({
    step: Number,
})

const form = usePage().props.form
const stepsCount = form?.layout?.children?.length || 0
const title = computed(() => form?.layout?.children[props.step]?.title)
</script>
