<template>
    <div
        class="h-screen flex items-center overflow-y-scroll too-tall"
        :class="{
            '!items-start': formTooTall,
            'mt-20': !formTooTall,
        }"
    >
        <fun-bg />

        <div
            ref="wrapper"
            class="form-container"
            :class="{
                'mt-5 sm:mt-20 lg:mt-32': formTooTall,
            }"
        >
            <slot />
        </div>
    </div>
</template>
<script setup>
import FunBg from '@/Components/FunBg.vue'
import { onMounted, ref } from 'vue'

const wrapper = ref(null)
const formTooTall = ref(false)

onMounted(() => {
    const formWrapperHeight = wrapper.value.clientHeight

    // if the form wrapper height represents more than 80% of the window height
    if (formWrapperHeight > window.innerHeight * 0.8) {
        formTooTall.value = true
    } else {
        formTooTall.value = false
    }
})
</script>
