<template>
    <product-detail-modal :show="show" @close="$emit('close')" :max-width="'productDetailModal'">
        <div>
            <div>
                <div class="embla" ref="emblaRef">
                    <div class="embla__container">
                        <!-- 1280x720 -->
                        <div class="embla__slide bg-slate-300"><span>Slide 1</span></div>
                        <div class="embla__slide bg-teal-200"><span>Slide 2</span></div>
                        <div class="embla__slide bg-blue-400"><span>Slide 3</span></div>
                    </div>
                </div>
                <button @click="prev">prev</button>
                <button @click="next">next</button>
            </div>
            <div class="p-5">
                <h1 class="text-3xl font-semibold text-gray-700 tracking-tight leading-relaxed">Plyometric Program - Increase your Vertical</h1>
                <div class="max-w-[700px]">
                    <p class="text-lg">
                        Workout 1 consists of short ground contact time jumping exercises. These are more tendon based jumps, focused on getting off the ground
                        as quick.
                    </p>
                </div>
                <div class="h-[1100px]"></div>
            </div>
        </div>
    </product-detail-modal>
</template>
<script setup>
import { defineProps, onMounted } from 'vue'
import ProductDetailModal from '@/Pixelform/ProductDetailModal.vue'
import emblaCarouselVue from 'embla-carousel-vue'

const props = defineProps({
    show: Boolean,
})

defineEmits(['close'])

const [emblaRef, emblaApi] = emblaCarouselVue({ loop: true })

console.log(emblaApi)
const prev = () => emblaApi.value.scrollPrev()
const next = () => emblaApi.value.scrollNext()

onMounted(() => {
    emblaApi.value.slidesNotInView()
})
</script>

<style scoped>
.embla {
    overflow: hidden;
}
.embla__container {
    display: flex;
}
.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    height: 520px;
    justify-content: center;
    align-items: center;
}
</style>
