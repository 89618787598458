import './bootstrap'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import VCalendar from 'v-calendar'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { usePage } from '@inertiajs/vue3'

createInertiaApp({
    id: 'app',
    plugins: [VCalendar],
    resolve: (name) => {
        const pages = import.meta.glob('./Pixelform/**/*.vue', { eager: true })
        return resolvePageComponent(`./Pixelform/${name}.vue`, pages)
    },

    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
        const page = usePage()

        app.config.globalProperties.$route = route
        app.config.globalProperties.$page = page

        app.use(plugin)
            .use(VCalendar, {
                componentPrefix: 'formfield',
            })
            .mount(el)
    },
})
