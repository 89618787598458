<template>
    <form-hero :type="form.hero_type" :content="form.hero_content" :hero="hero">
        <form-wrapper :hero-type="form.hero_type">
            <form-thanks v-if="thanks" :thanks="form.thanks" />
            <form-content
                v-else
                :form="formContentProps"
                @done="thanks = true"
            />
        </form-wrapper>
        <!-- <form-powered-by v-if="!form.logo" /> -->
    </form-hero>
</template>
<script setup>
import FormThanks from '@/Components/FormThanks.vue'
import FormContent from './FormContent.vue'
import FormHero from './FormHero/FormHero.vue'
import FormWrapper from './FormWrapper.vue'
import { ref, computed } from 'vue'

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
})

const thanks = ref(false)
const formContentProps = computed(() => ({
    name: props.form.name,
    _id: props.form._id,
    layout: props.form.layout,
    fields: props.form.fields,
    return_url: props.form.return_url,
    token: props.form.token,
    logo: props.form.logo,
    hero_type: props.form.hero_type,
}))
const hero = {
    type: props.form.hero_type,
    background_solid: props.form.hero_bg_solid_color,
    overlay: props.form.hero_overlay,
    width: props.form.hero_width,
    size: props.form.hero_size,
    position: props.form.hero_position,
    repeat: props.form.hero_repeat,
    backgroundUrl: props.form.hero_bg_url,
    link_label: props.form.hero_link_label,
    link_url: props.form.hero_link_url,
    logo_url: props.form.hero_logo_url,
}
</script>
