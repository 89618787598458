<template>
    <div class="flex flex-col md:flex-row md:h-screen md:overflow-hidden">
        <form-hero-content :hero="hero" :content="content" />
        <div
            class="relative flex flex-1 justify-center items-start lg:overflow-y-auto bg-white"
            :class="{
                'items-center': !formTooTall,
            }"
        >
            <div
                ref="wrapper"
                class="form-container"
                :class="{
                    'mt-5 sm:mt-20 !mx-0 lg:mt-32': formTooTall,
                }"
            >
                <slot />
            </div>
        </div>
    </div>
</template>
<script setup>
import FormHeroContent from '@/Pixelform/Client/FormHero/Content.vue'
import { onMounted, ref } from 'vue'

const props = defineProps({
    content: {
        type: [Object, null],
        required: true,
    },
    hero: {
        type: Object,
        required: true,
    },
})

const wrapper = ref(null)
const formTooTall = ref(false)

onMounted(() => {
    const formWrapperHeight = wrapper.value.clientHeight
    // if the form wrapper height represents more than 80% of the window height
    if (formWrapperHeight > window.innerHeight * 0.8) {
        formTooTall.value = true
    } else {
        formTooTall.value = false
    }
})
</script>
