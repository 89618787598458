<template>
    <div
        class="relative overflow-x-hidden flex-1 md:h-screen no-scrollbar md:overflow-y-scroll"
        :class="{
            'pf-form-overlay': hero.overlay,
        }"
        :style="heroBgStyle"
    >
        <div v-if="content?.blocks" class="relative no-scrollbar md:h-screen md:overflow-y-scroll xl:z-10 py-4 sm:py-8 px-8">
            <div v-if="hero.logo_url" class="relative inline-flex h-14 w-16 items-center justify-center mb-10 z-10 bg-white rounded-md">
                <img :src="hero.logo_url" class="object-contain h-12 w-12" />
            </div>
            <hero-content-component v-for="block in content.blocks" :key="block.id" :block="block" class="z-20" />
        </div>
        <div v-else class="relative no-scrollbar md:h-screen md:overflow-y-scroll xl:z-10 py-4 sm:py-8 px-8">
            <div v-if="hero.logo_url" class="relative inline-flex h-14 w-16 items-center justify-center mb-10 z-10 bg-white rounded-md">
                <img :src="hero.logo_url" class="object-contain h-12 w-12" />
            </div>
        </div>
    </div>
</template>
<script setup>
import HeroContentComponent from '@/Pixelform/Client/HeroContentComponent.vue'
import { computed } from 'vue'

const props = defineProps({
    content: {
        type: [Array, Object],
    },
    hero: {
        type: Object,
        required: true,
    },
})

const heroBgStyle = computed(() => {
    if (props.hero.background_solid) return `background-color: ${props.hero.background_solid};`

    const opacity = 1 - parseFloat(props.hero.overlay?.split(',')?.pop().slice(0, -1))

    return `background-color: black; background-size: ${props.hero.size}; background-position: ${props.hero.position}; background-repeat: ${props.hero.repeat}; background-image:url(${props.hero.backgroundUrl}); height: 100%; opacity: ${opacity};`
})
const hasOverlay = !props.hero.background_solid && props.hero.overlay?.length > 0
</script>
